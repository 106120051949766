<template>
  <div class="Site PageBox">
    <NavBar :title="$t('hall.default[2]')" :page-number="-1"/>
    <div class="ScrollBox">
      <div v-if="pageState==0">
      <div class="header0">
          <div class="MyHeader2">
              <img class="head" :src="UserInfo.header.includes('head_')?`./static/head/${UserInfo.header}`:UserInfo.header" />
          </div>
          <div>{{ UserInfo.username.substring(0,15) }}</div>
      </div>
    <div class="infoBox border-grey" style="padding: 20px; line-height: 25px;color:var(--font_subtitle); ">
      <div>
        <div>{{$t('bindAccount.fields[0]')}} :{{CardInfo.name}}</div>
      </div>
      <div>
        <div style="font-size:15px; margin: 6px 0 6px;"> USDT{{ $t("hall.default[1]") }}:
            <span style="color:var(--bc-text-color-0);font-weight: bold">{{ parseFloat(UserInfo.balance).toFixed(2)}}</span></div>
        <div>{{$t('buy.label[2]')}} :{{CardInfo.card_no}}</div>
      </div>
    </div>
        <div class="infoBox gradient-panel">
          <div style="display: flex;padding: 15px">
            <div style="line-height: 25px;width: 50%">
              <div style="font-size: 17px;font-weight: bold">{{parseFloat(CoinInfo.price).toFixed(2)}}</div>
              <div  style="font-size: 13px;">{{$t('sell.label[3]')}}</div>
              <div style="font-size: 13px; color: var(--bc-text-color)">USDT</div>
            </div>
            <div class="shu" style="height: 78px; background-color: var(--bc-text-color) "></div>
            <div style="width: 10%"></div>
            <div style="line-height: 25px;width: 40%">
              <div style="font-size: 17px;font-weight: bold">{{parseFloat(CoinInfo.bestPrice).toFixed(2)}}</div>
              <div style="font-size: 13px;">{{$t('sell.label[4]')}}</div>
              <div style="font-size: 13px; color: var(--bc-text-color)">USDT</div>
            </div>
          </div>
        </div>
      <div class="infoBox border-grey">

        <div>
          <div>
            <div style="display: flex;">
              <h3> {{ $t('buy.label[5]') }}</h3><div style="color: var(--bc-text-color-7)">*</div>
            </div>
            <div style="display: flex; align-items: center;">
              <div style="width: 80%;">
                <van-field required
                           class="input" :border="false"
                           v-model="BankInfo.price"
                           :placeholder="$t('buy.placeholder[0]')"
                />
              </div>
              <div style="margin-left: 10px;">
                <van-button size="min" style="width: 100px;height: 30px;margin-top: 5px" @click="setPrice">
                  <span style="font-size: 12px">{{ $t('buy.label[7]') }}</span>
                </van-button>
              </div>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <div style="display: flex;">
              <h3>{{ $t('buy.label[6]') }}</h3><div style="color: var(--bc-text-color-7)">*</div>
            </div>
            <div>
              <van-field required
                         class="input" :border="false"
                         v-model="BankInfo.total_number"
                         :placeholder="$t('buy.placeholder[1]')"
              />
            </div>
          </div>


          <div style="margin-top: 20px;">
            <div style="display: flex;">
              <h3>{{ $t('buy3[0]') }}</h3><div style="color: var(--bc-text-color-7)">*</div>
            </div>
            <div>
              <van-field required readonly
                         class="input" :border="false"
                         v-model="counttimestr"
                         @click="showPicker = true"
                         :placeholder="$t('buy.placeholder[1]')"
              />
            </div>
          </div>

          <van-popup v-model:show="showPicker" round position="bottom" style="background: #FFFFFF">
            <van-picker show-toolbar :title="$t('buy3[0]')" :confirm-button-text="$t('common[2]')"
                        :cancel-button-text="$t('common[3]')"
                        :columns="columns"
                        @cancel="showPicker = false"
                        @confirm="onConfirm"
            >
              <van-field slot="title" required
                         style="margin:0 auto;width: 100px;text-align: center;border: 2px solid;height: 30px;border-radius: 5px"
                         class="input" type="digit" maxlength="4" v-model="selfCounttime"
                         :placeholder="$t('buy.placeholder[1]')"
              >
              </van-field>
              <div class="tool" slot="toolbar">
              </div>
            </van-picker>
          </van-popup>

          <div style="margin-top: 20px;">
            <div style="display: flex;">
              <h3>{{ $t('buy3[2]') }}</h3><div style="color: var(--bc-text-color-7)">*</div>
            </div>
            <div>
              <van-field required readonly
                         class="input" :border="false"
                         v-model="ordertypestr"
                         @click="showPicker2 = true"
                         :placeholder="$t('buy.placeholder[1]')"
              />
            </div>
          </div>


          <van-popup v-model:show="showPicker2" round position="bottom">
            <van-picker show-toolbar :title="$t('buy2[0]')" :confirm-button-text="$t('common[2]')"
                        :cancel-button-text="$t('common[3]')"
                        :columns="columntypes"
                        @cancel="showPicker2 = false"
                        @confirm="onConfirmType"
            />
          </van-popup>

          <div v-if="BankInfo.ordertype==0" style="margin-top: 20px;">
            <h3>{{ $t('buy3[7]') }}</h3>
            <div>
              <van-field
                  class="input" :border="false"
                  v-model="BankInfo.target_uid"
                  :placeholder="$t('buy3[8]')"
              />
            </div>
          </div>
          <div v-if="BankInfo.ordertype==1" style="margin-top: 20px;">
            <h3>{{ $t('buy3[5]') }}</h3>
            <div>
              <van-field
                  class="input" :border="false"
                  v-model="BankInfo.count_limit"
                  :placeholder="$t('buy3[6]')"
              />
            </div>
          </div>


          <div style="margin-top: 20px;">
            <div style="display: flex;">
              <h3>{{ $t('buy.label[10]') }}</h3><div style="color: var(--bc-text-color-7)">*</div>
            </div>
            <div>
              <van-field required :border="false" class="input"
                         v-model="BankInfo.min_number"
                         :placeholder="$t('buy.placeholder[2]')"
              />
            </div>
            <div>
              <van-field required :border="false" class="input"
                         v-model="BankInfo.max_number"
                         :placeholder="$t('buy.placeholder[3]')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="centerPanel" style="margin-top: 20px">
        <van-button class="gradient-btn" @click="onSubmit">{{ $t('buy.label[9]') }}</van-button>
      </div>
    </div>
      <div class="centerPanel" v-else>
          <div>
              <img src="../../public/static/icon/btn_done.png" style="width:50px"/>
          </div>
          <div style="font-size: 22px">{{$t('buy2[1]')}}</div>
          <div>{{BankInfo.total_number}}{{$t('buy2[2]')}}</div>
          <div style="margin: 10px auto;">
              <van-button class="gradient-btn" to="/hall">
                  <span style="font-size: 18px">{{ $t('sell4[5]') }}</span>
              </van-button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BindAccount',
    components: {

    },
    props: [],
    data() {
      return {
        tabsActive: 0,
        weixinAcc: '',
        pageState:0,
        selfCounttime:null,
        showUsdtType: false,
        showPicker:false,
        showPicker2:false,
        CardInfo:{},
        isVertical:true,
        robotList:[],
        counttimes:[2,6,12,24,72,500,2000],
        counttimestr:'',
        ordertypestr:'',
        columns : [],
        columntypes:[],
        BankInfo:{price:null,total_number:null,min_number:null,max_number:null,counttime:2,ordertype:0,count_limit:null,target_uid:null},
        CoinInfo:{price:0,bestPrice:0},
        douyinImg: [],
        kuaishouImg: [],
        accountArr: [],
        tabsList: [],
      }
    },
    computed: {
    },
    watch: {

    },
    created() {
      this.$Model.GetUserInfo()
      this.$Model.GetBankCardList(data=>{
        this.CardInfo = data.data[0];
      });

      this.counttimes.forEach(tt=>{
        var ctime = tt+" "+this.$t("buy3[1]");
        this.columns.push(ctime);
      });
      this.counttimestr = this.columns[0];
      this.BankInfo.counttime = this.counttimes[0];

      this.ordertypestr = this.$t("buy3[3]");
      this.columntypes.push(this.$t("buy3[3]"))
      this.columntypes.push(this.$t("buy3[4]"))

      let usertype = this.UserInfo.user_type;
      if (!usertype||usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        this.$router.push("/hall");
        return;
      }

      this.usdtinfo = this.InitData.usdtinfo;
      var pump = this.UserInfo.pump;
      this.CoinInfo['price'] = this.usdtinfo ['price'];
      this.CoinInfo['bestPrice'] = this.usdtinfo ['price']*(1+pump);

      this.$parent.navBarTitle = this.$t('bindAccount.default[0]')

      this.$Model.GetRobotTasks({},(data) => {
        // debugger
        if (data['code']==1){
          this.robotList = data['info']
        }
      });


      // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
      // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
    },
    mounted() {

    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      setPrice(){
        this.BankInfo.price = parseFloat(this.CoinInfo.bestPrice).toFixed(2);
      },
      setAmount(){
        this.BankInfo.total_number=this.UserInfo.balance;
      },
      onSubmit() {
        var bb = parseInt(this.BankInfo.min_number)>parseInt(this.BankInfo.max_number);
        if (this.BankInfo.price<=0){
          this.$Dialog.Toast(this.$t('mytask2[9]'));
          return;
        } else if (this.BankInfo.total_number<=0){
          this.$Dialog.Toast(this.$t('mytask2[7]'));
          return;
        } else if (this.BankInfo.min_number<=0||this.BankInfo.max_number<=0||bb){
          this.$Dialog.Toast(this.$t('mytask2[10]'));
          return;
        }
        this.BankInfo.name = this.CardInfo['name'];
        this.BankInfo.card_no = this.CardInfo['card_no'];
        this.BankInfo.task_type = 1;
        this.$Model.SubmitTask(
                this.BankInfo,
                (data) => {
                  if (data==-1){
                    this.$Dialog.Toast(this.$t('taskOrder2[3]'));
                    return;
                  }
                  console.log("buy: "+JSON.stringify(data));
                  let msg = '';
                  switch (data.code){
                    case 1:
                      msg = this.$t('common3[0]');
                      break;
                    case -1:
                      msg = this.$t('mytask2[7]');
                      break;
                    case -2:
                      msg = this.$t('mytask2[8]');
                      break;
                    case -3:
                      msg = this.$t('mytask2[9]');
                      break;
                    default:
                      msg = data.code_dec;
                      break;
                  }
                  this.$Dialog.Toast(msg);
                  if (data.code==1){
                      this.pageState = 1
                  }
                }
        );
      },
    onConfirm(obj,index){
        var selfc = this.selfCounttime;
        if (selfc.length>0){
          this.BankInfo.counttime = this.selfCounttime;
          this.counttimestr = selfc+this.$t("buy3[1]");
          this.selfCounttime = null;
          this.showPicker = false;
          return;
        }
        console.log(index);
        this.counttimestr = this.columns[index];
        this.showPicker = false;
        this.BankInfo.counttime = this.counttimes[index]
    },
      onConfirmType(obj,index){
        console.log(index);
        this.ordertypestr = obj;
        this.showPicker2 = false;
        this.BankInfo.ordertype = index;
      },
    }
  }
</script>
<style scoped>
.PageBox {
  overflow-y: auto;

}
.ScrollBox {
  padding-top: 10px;
}
.van-nav-bar {

}



.van-cell>>>.van-cell__title{
  font-size: 15px;
}
.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
  margin: 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}
.header0 {
  width: 95%;
  border-radius: 10px;
    background:transparent;
  display:flex;
  font-size: 17px;
  padding: 15px;
  padding-left: 15px;
  line-height: 40px;
  margin: 0px auto;
}

.MyHeader2 {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}
.MyHeader2 img {
  width: 100%;
}
.panelHeader {
  margin-top: 0px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #cccccc;
  width: 40%;
  text-align: center;
  margin-bottom: 10px;
}
.van-cell {
  background: transparent;
}

.PageBox .van-cell>>>.van-cell__title{
  font-size: 15px;
  font-weight: bold;
}
.van-cell >>> .van-field__control {
  padding-left: 7px;
  height: 100%;
}

.van-cell.input >>> .van-field__control{
  height: 30px;
  line-height: 30px;
  background-color:var(--cell_bg);
  border-radius: 20px;
  padding: 10px;
}

.van-cell--borderless.input >>> .van-field__control {
    color: var(--cell_title) !important;
    padding-left: 15px;
    font-size: 15px;
    background-color: var(--main-bg-color);
    border: 1px solid #404040;


}

.van-cell >>> .van-field__control::-webkit-input-placeholder {

    color: var(--bc-text-color-3) !important;
}

.van-cell >>> .van-field__control::-moz-placeholder {

    color: var(--cell_placeholder);
}

.van-popup {
  background-color: #4e51bf;
}

.hallswipe {
  width: 96%;
  margin-left: 6px;
  padding: 15px;
  height: 60px;
  z-index: 50;
  margin-top: 5px;
  margin-bottom: 5px;
  color:#cccccc;

}

.robotheader{
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 32px;
  height: 32px;
}
.infoBox {
  margin-left: 10px;
  border-radius: 10px;
  background-color: var(--panel_color);
  padding: 10px;
  margin: 10px;
}

.btn {
  width: 90%;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: var(--button_bg);
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 15px 20px 30px;
}
</style>
